import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, take, tap, filter, switchMap } from 'rxjs/operators';
import * as AuthActions from '../store/actions/auth.actions';
import * as fromAuth from '../store/reducers/auth.reducer';
import { User } from '../../../../../shared/model/user.interface';
import { UserRole } from '../../../../../shared/model/user-role.enum';

@Injectable()
export class AdminGuard implements CanActivate {

    constructor(
        private afAuth: AngularFireAuth,
        private router: Router,
        private store: Store<fromAuth.State>,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        // Check if user is logged in
        return this.afAuth.authState.pipe(
            take(1),
            map((firebaseUser: firebase.User) => {
                if (!firebaseUser) {
                    this.store.dispatch(new AuthActions.SaveLoginRedirectUrl(state.url));
                    this.router.navigate(['/login']);
                    return false;
                }
            }),
            // Check if logged user is admin
            switchMap(() => this.store.select(fromAuth.getUser).pipe(
                filter(user => !!user),
                take(1),
                map((user: User) => {
                    if (user.orgRole === UserRole.Admin) {
                        return true;
                    } else {
                        return false;
                    }
                })
            ))
        )

    }


}
