import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DialogComponent } from './dialog.component';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Injectable()
export class DialogService {

  constructor(private dialog: MatDialog) { }

    public open(title: string, message: string, closeButtonText: string, confirmButtonText?: string) {

        let dialogRef: MatDialogRef<DialogComponent>;

        dialogRef = this.dialog.open(DialogComponent);
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        dialogRef.componentInstance.closeButtonText = closeButtonText;
        dialogRef.componentInstance.confirmButtonText = confirmButtonText;

        return dialogRef.afterClosed();
    }

}
