export class FeedbackTag {
  id?: string;
  createdAt?: any;
  modifiedAt?: any;
  name: string; 
  icon: string;
  active: boolean;
}


